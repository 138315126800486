@import url(https://fonts.googleapis.com/css?family=Raleway);
body {

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .background {
    background: url(../turtlelogo.jpg) no-repeat top center fixed;
    background-size: cover;
} */
.middle {
    position: absolute;
    top: 50%;
    left: 50%;
}

.stepper {
    height: 100%;
}

.padded {
    padding: 10px;
    /* margin-top: 50px */
}

.label {
    border: 10px;
}

.checked {
    background-color: lightgreen;

}

.unchecked {
    background-color: lightsalmon;
}

.bottomBar {
    background-color: lightgoldenrodyellow
}

.table-header {
    background-color: '#fff';
    position: 'sticky';
    top: 0;
    z-index: 10000000;
}

.confirm-button {
    height: 2;
    width: 2;
}

.relative{
    -webkit-transform: translateX(0);
            transform: translateX(0);
    
  }

.ticket-button {
    align-self: flex-end;
}
.center{
    font-family: 'Raleway', sans-serif;
    background-color: black;
    
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card{
    /* background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255,255,255,0.8)), url("./tl_logo.jpg") no-repeat;
    background-size: 400px 255px; */
    background-color: #fff;
    border-radius: 15px;
    padding: 0.8rem;
}

.card > form{
    display: flex;
    flex-direction: column;
}

.card h1{
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.form-item{
    font-family: 'Raleway', sans-serif;
    padding: 5px;
    margin-bottom: 2rem;
    height: 30px;
    width: 16rem;
    border: 1px solid grey;
}

.form-submit{
    font-family: 'Raleway', sans-serif;
    height: 35px;
    color: #fff;
    background-color: black;
    border: none;
    letter-spacing: 0.2rem;
    -webkit-transition: 0.3s opacity ease;
    transition: 0.3s opacity ease;
    cursor: pointer;
}
.form-submit:hover{
   opacity: 0.6;
}
